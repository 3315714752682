<template>
  <div id="contact">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadSections
      class="contact-img"
      :TitlePage="this.$i18n.locale == 'ar' ? 'نتشرف بالتواصل معكم!':'We are honored to communicate with you!'"
      :TitleSec="this.$i18n.locale == 'ar' ? 'كن على معرفة بكل ما هو جديد.' : 'Stay up to date with everything new.'"
      :PathPageFrom="$t('Home')"
      :PathPageTo="$t('Contact')"
      :pathFrom="`/`"/>
    <div class="container" style=" overflow: hidden;margin-top: -90px;">
      <IndexContact/>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Contact',
  components: {
    HeadSections: defineAsyncComponent(() => import('@/components/Global/HeadSections.vue')),
    IndexContact: defineAsyncComponent(() => import('@/components/Contact/IndexContact.vue')),
  },
}
</script>
